<template>
  <div>
    <van-nav-bar title="使用手册" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <ul>
        <li class="enable" v-for="(product, index) in products" :key="index">
          <div class="pic"><img :src="product.image" alt=""></div>
          <h2>{{ product.name }}</h2>
          <a :href="product.file" target="_blank"><span>说明书下载</span><i></i></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { CDN_URL } from '@/config'

export default {
  name: 'download',
  data () {
    return {
      products: [
        {
          image: 'https://img-cdn.zepp.com/webapp-create-platform/amazfit/cn/service/download/4bdcce5ea95c56185daaadf8a75b3551.jpeg',
          name: 'Amazfit Active 2',
          file: 'https://upload-cdn.zepp.com/webapp-create-platform/amazfit/cn/service/download/Amazfit Active 2 NFC（Round）产品使用手册-国内版.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/webapp-create-platform/amazfit/cn/service/download/c01b3a49fa1ce8475a5d6754e1b5f30c.jpeg',
          name: 'Amazfit T-Rex 3',
          file: 'https://upload-cdn.zepp.com/webapp-create-platform/amazfit/cn/service/download/Amazfit T-Rex 3 产品使用手册 - 国内版.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/webapp-create-platform/amazfit/cn/service/download/38570ac9c66e2830a4971d3bf6f0c739.jpeg',
          name: 'Amazfit Active Edge',
          file: 'https://upload-cdn.zepp.com/webapp-create-platform/amazfit/cn/service/download/Amazfit Active Edge 产品使用手册-国内版.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/amazfit/cn/service/download/1661eccb8460c674fae429dfc73bd877.jpeg',
          name: 'Amazfit Bip 5',
          file: 'https://upload-cdn.zepp.com/amazfit/cn/service/download/Amazfit Bip 5  用户手册.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/amazfit/cn/service/download/56f999248057bfdffc0f4158968a4148.jpeg',
          name: 'Amazfit Active',
          file: 'https://upload-cdn.zepp.com/amazfit/cn/service/download/Amazfit Active 使用手册.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/amazfit/cn/service/download/be86e9cbcf91d866f7448f14c2d1e62f.jpeg',
          name: 'Amazfit Balance',
          file: 'https://upload-cdn.zepp.com/amazfit/cn/service/download/Amazfit Balance 使用手册.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/amazfit/cn/service/download/20230807-160734.jpeg',
          name: 'Amazfit Cheetah Pro',
          file: 'https://upload-cdn.zepp.com/amazfit/cn/service/download/Amazfit Cheetah Pro 使用手册.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/amazfit/cn/service/download/20230517-114516.png',
          name: 'Amazfit T-Rex Ultra',
          file: 'https://upload-cdn.zepp.com/amazfit/cn/service/download/Amazfit T-Rex Ultra 产品使用手册 - 国内版.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/amazfit/cn/service/download/20230216-141236.png',
          name: 'Amazfit Falcon',
          file: 'https://upload-cdn.zepp.com/amazfit/cn/service/download/Amazfit 跃我 Falcon 使用手册（国内版）.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/gtr4.png',
          name: 'Amazfit GTR 4',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/Amazfit GTR 4 使用手册（国内版）.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/gts4.png',
          name: 'Amazfit GTS 4',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/Amazfit GTS 4 使用手册（国内版）.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/GTS-4-mini.png',
          name: 'Amazfit GTS 4 mini',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/Amazfit GTS 4 mini 使用手册(国内版）.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/T-Rex2产品图片.png',
          name: 'Amazfit T-Rex 2',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/T-Rex2使用手册.pdf'
        },
        {
          image: 'https://img-cdn.zepp.com/amazfit/cn/service/download/b3a498dfc8863193d729b5a7d725ad20.jpg',
          name: 'Amazfit GTR 3 Pro',
          file: 'https://site-cdn.huami.com/files/amazfit/mobile/download/GTR3Pro-使用手册.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/mobile/download/GTR3.png',
          name: 'Amazfit GTR 3',
          file: 'https://site-cdn.huami.com/files/amazfit/mobile/download/GTR3-使用手册.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/mobile/download/GTS3.png',
          name: 'Amazfit GTS 3',
          file: 'https://site-cdn.huami.com/files/amazfit/mobile/download/GTS3-使用手册.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/mobile/download/PowerBudsPro.png',
          name: 'Amazfit PowerBuds Pro',
          file: 'https://site-cdn.huami.com/files/amazfit/mobile/download/PowerBudsPro-使用手册.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/mobile/download/快乐鸭.png',
          name: 'Amazfit 快乐鸭儿童健康手表',
          file: 'https://site-cdn.huami.com/files/amazfit/mobile/download/快乐鸭-使用手册.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/gtr2e.png',
          name: 'Amazfit GTR 2e',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/gtr2e.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/gts2e.png',
          name: 'Amazfit GTS 2e',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/gts2e.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/gts2-mini.png',
          name: 'Amazfit GTS 2 mini',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/gts2-mini.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/pop-pro.png',
          name: 'Amazfit Pop Pro',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/pop-pro.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/pop.png',
          name: 'Amazfit Pop',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/pop.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/zenbuds.png',
          name: 'Amazfit ZenBuds',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/zenbuds.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/cn/service/download/amazfit-x.png',
          name: 'Amazfit X',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/amazfit-x.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/service/download/GTS2.png',
          name: 'Amazfit GTS 2',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/GTS2.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/service/download/GTR2.png',
          name: 'Amazfit GTR 2',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/GTR2.pdf'
        },
        {
          image: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/service/download/neo.png',
          name: 'Amazfit Neo',
          file: 'https://site-cdn.huami.com/files/amazfit/cn/pdf/neo.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/airrun.png',
          name: 'Amazfit AirRun',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/airrun.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/ares.png',
          name: 'Amazfit Ares',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/ares.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/bip-s.png',
          name: 'Amazfit 米动手表青春版1S',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/bip-s.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/powerbuds.png',
          name: 'Amazfit Powerbuds',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/powerbuds.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/t-rex.png',
          name: 'Amazfit T-Rex',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/T-Rex.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/gts.png',
          name: 'Amazfit GTS',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/gts.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/gtr.png',
          name: 'Amazfit GTR',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/gtr.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/stratos-3.png',
          name: 'Amazfit 智能运动手表 3',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/stratos-3.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/nexo.png',
          name: 'Amazfit 智能手表 2',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/nexo.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/verge.png',
          name: 'Amazfit 智能手表',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/verge.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/verge-lite.png',
          name: 'Amazfit 智能手表青春版',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/verge-lite.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/stratos.png',
          name: 'Amazfit 智能运动手表 2/2S',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/stratos.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/pace.png',
          name: 'Amazfit 智能运动手表',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/pace.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/bip.png',
          name: 'Amazfit 米动手表 青春版',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/bip.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/band2.png',
          name: 'Amazfit 米动手环 2',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/band2.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/cor.png',
          name: 'Amazfit 米动手环',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/cor.pdf'
        },
        {
          image: CDN_URL + '/images/service/download/health-watch.png',
          name: 'Amazfit 健康手表',
          file: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/pdf/health-watch.pdf'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  font-size: 0;
}

li {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 0 8px 20px;
  font-size: 12px;
  border-radius: 8px;
  text-align: center;
  border-bottom: 1px solid #f3f3f3;

  h2 {
    font-size: 20px;
    color: #555;
    margin: 10px 0 0;
    border-left: 0;
    padding-left: 0;
    font-size: 12px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pic{
    height: auto;
    width: 100%;
    text-align: center;

    img{
      // width: 70%;
      // height: auto;
      height: 35vw;
    }
  }

  a {
    display: inline-block;
    color: #ff7e4d;
    padding: 4px 8px;
    border: 1px solid #ff7e4d;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 10px;

    span {
      padding-right: 8px;
      border-right: 1px solid #ff7e4d;
      font-weight: 200;
    }

    i {
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      width: 10px;
      height: 13px;
      background: url(https://site-cdn.huami.com/files/amazfit/mobile/assets/service/down-icon-2.png) no-repeat;
      background-size: 100% 100%;
    }
  }

  &:nth-child(odd){
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 100%;
      border-right: 1px solid #f3f3f3;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
</style>
